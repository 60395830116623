.container-cambiar-clave{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 35px;
}

.box-input-cambiar-clave{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.loader-solicitud-clave{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}