@import '../../styles/globalStyles.css';

.container-beneficio-vista{
    border: dotted 1px rgb(190, 190, 190);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px;
}

.img-principal-beneficio-vista{
    width: 150px;
    height: 150px;
}

.box-beneficio-vista{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.titulo-beneficio-vista{
    font-size: 18px;
}

.span-beneficio-vista{
    font-size: 12px;
}

.parrafo-beneficio-vista{
    font-size: 14px;
}

.inbox-beneficio-vista{
    margin: 7px 0;
}

.inbox2-beneficio-vista{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.img-banner-beneficio-vista{
    width: 90%;
}

.boxsuperior-beneficio-vista, .boxsuperior2-beneficio-vista{
    width: 50%;
}

.boxsuperior-beneficio-vista{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.boxsuperior2-beneficio-vista{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.boton-eliminar-beneficio-vista{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 3px 5px 3px 0;
    background-color: var(--color-rojo);
    border: none;
    color: white;
    transition: all 400ms;
}

.boton-eliminar-beneficio-vista:hover{
    background-color: #910a0f;
}

.container-beneficio-vista-hidden{
    display: none;
}

@media (max-width: 900px) {
 
    .box-beneficio-vista{
        flex-direction: column;
    }
    .container-beneficio-vista{
        flex-direction: column;
    }
    .boton-agregar-concurso-admin{
        justify-content: center;
    }
    .boton-eliminar-beneficio-vista{
        position: relative;
    }
    .container-beneficio-vista{
        align-items: center;
    }
}