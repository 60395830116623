.titulo-vista-concursos-admin{
    text-align: center;
    margin: 60px 0 30px 0;
}

.boton-agregar-concurso-admin{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0 15px 0;

}

.boton-agregar-concurso-admin > button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border: none;
    padding: 7px;
    background-color: green;
    color: white;
    cursor: pointer;
    transition: all 400ms;
}

.boton-agregar-concurso-admin > button:hover{
    background-color: rgb(0, 78, 0);
}

.box-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin-top: 200px;
}

.box-loader > p{
    font-size: 16px;
    font-weight: bold;
}