.ad-image {
  width: 50%;
  margin: auto 25%;
}

@media (max-width: 900px) {
  .ad-image {
    width: 100%;
    margin: auto;
  }
}
