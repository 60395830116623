@import '../../styles/globalStyles.css';

.main-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 45px;
    border: solid 1px #b9b8b8;
    padding-left: 14px;
    color: black;
    cursor: pointer;
    font-family: var(--font-family-regular);
    font-style: normal;
    font-size: 12px;
    font-weight: bold;
    z-index: 100;
}

.main-input::placeholder {
    font-family: var(--font-family-regular);
    font-style: normal;
    font-size: 12px;
    font-weight: bold;
    color: rgb(190, 190, 190);
}

.input-pswd {
    position: relative;
}

.icon-input {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
}

.width2 {
    width: 712px;
}

.width3 {
    width: 175px;
}

.width4 {
    width: 240px;
}

.span-date {
    position: absolute;
    top: 11px;
    left: 14px;
    font-family: var(--font-family-regular);
    font-style: normal;
    font-size: 14px;
    font-weight: bold;
    color: rgb(190, 190, 190);
    cursor: default;
    z-index: -1;
}


.span-date-none {
    display: none;
}

.input-date-placeholder[type="date"] {
    font-family: var(--font-family-regular);
    font-style: normal;
    font-size: 14px;
    font-weight: bold;
    color: rgb(190, 190, 190);
}

.input-date[type="date"] {
    text-align: end;
    padding-right: 15px;
    background-color: transparent;
}

.input-date-ok[type="date"] {
    text-align: start;
}

.input-date-ok-mobile[type="date"] {
    display: flex;
    justify-content: flex-start;
}

.input-date[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    cursor: default;
    pointer-events: none;

}

.main-input[type='number']::-webkit-inner-spin-button,
.main-input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.defaultValue {
    color: rgb(190, 190, 190);
}

.defaultValueNone {
    color: black;
}

/* media queries for smaller screens */
@media screen and (max-width: 600px) {

    .main-input {

        width: 250px;

    }

    .icon-input {
        position: absolute;
        top: 8px;
        /* right: 60px; */
        cursor: pointer;
    }

}