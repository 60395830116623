.container-oyente{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-oyente{
    justify-content: flex-start;
    margin-top: 80px;
}

.box-oyente, .box2-oyente{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-oyente{
    margin-top: 20px;
    margin-bottom: 40px;
}

.box2-oyente-perfil-home{
    margin-top: 30px;
}

.loader-oyente{
    margin: 0;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px){
    .container-oyente{
        margin-top: 20px;
    }
}

