@import '../../styles/globalStyles.css';

.container-beneficio-agregar {
    border: dotted 1px rgb(190, 190, 190);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 15px;
}

.img-input-beneficio-agregar {
    position: relative;
    width: 140px;
    height: 140px;
    border: solid 1px rgb(190, 190, 190);
}

.vista-img-agregar-benedicio {
    width: 138px;
    height: 137px;
}

.img-input-beneficio-agregar>input {
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.img-input-beneficio-agregar>span {
    position: absolute;
    top: 55px;
    left: 6px;
    cursor: pointer;
}

.img-input-beneficio-agregar>div {
    position: absolute;
    bottom: 0;
    right: 6px;
    cursor: pointer;
}

.box-beneficio-agregar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.titulo-beneficio-agregar {
    font-size: 18px;
}

.span-beneficio-agregar {
    font-size: 12px;
    text-align: center;

}

.parrafo-beneficio-agregar {
    font-size: 14px;
}

.input_with__char_limit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
}

.inbox-beneficio-agregar {
    height: 200px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    flex-direction: column;
}

.inbox-beneficio-agregar>div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.inbox2-beneficio-agregar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.img-banner-beneficio-agregar {
    width: 90%;
}



.boxsuperior-beneficio-agregar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.boxsuperior2-beneficio-agregar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    height: 140px;
}

.limit__Char {
    color: #d30000;
    font-weight: bold;
    font-size: 13px;
}

.boton-eliminar-beneficio-agregar,
.boton-guardar-beneficio-agregar {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 3px 5px 3px 0;
    background-color: white;
    border: solid 1px var(--color-rojo);
    color: var(--color-rojo);
    transition: all 400ms;
    width: 100px;
}

.boton-guardar-beneficio-agregar {
    background-color: green;
    color: white;
    gap: 5px;
    border: solid 1px green;
}

.boton-guardar-beneficio-agregar:hover {
    background-color: rgb(0, 94, 0);
}

.boton-eliminar-beneficio-agregar:hover {
    background-color: var(--color-rojo);
    color: white;
}

.textarea-beneficio-agregar {
    width: 100%;
    height: 100%;
    resize: none;
    border: solid 1px rgb(190, 190, 190);
}

.boxsuperior-botones-beneficio-agregar {
    border: dotted 1px rgb(190, 190, 190);
    border-top: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 20px;
}

.box-icon-imagen-agregar-concurso {
    color: var(--color-rojo);
    background-color: white;
    height: 25px;
}

.container-superior-beneficio-agregar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 600px) {

    .container-beneficio-agregar {
        flex-direction: column;
    }

    .boxsuperior-botones-beneficio-agregar {
        flex-direction: column;
    }
}