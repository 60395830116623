.container-perfil-oyente, .box-inputs-perfil-oyente, .botones-perfil-oyente, .box-volver-perfil{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.container-box-perfil-oyente{
    width: 712px;
}

.box-inputs-perfil-oyente{
    flex-direction: row;
    gap: 12px;
    margin-bottom: 20px;
}

.texto-perfil-oyente{
    margin: 10px 0 20px 0;
    text-align: start;
    font-size: 12px;
}

.botones-perfil-oyente{
    width: 100%;
    margin-top: 35px;
    gap: 15px;
}

.box-volver-perfil{
    flex-direction: row;
    cursor: pointer;
}

.texto-volver-perfil{
    font-size: 11px;
}

.opcion-nav-oyente-cerrasesion{
    margin: 15px 0;
}

.box-volver-perfil-oyente{
    margin-bottom: 15px;
}
.date-picker{
    width: 100%;
}

@media only screen and (max-width: 600px){
    .container-box-perfil-oyente{
        width: 100%;
    }

    .texto-perfil-oyente{
        text-align: center;
    }
}