/* .publinota-container {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 220px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
}

.paralelogramo {
    margin-left: 50px;
    width: 380px;
    height: 100%;
    background: #DCDCDC;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    transform: skew(-20deg);

}

.publinota-title-section {
    margin-left: 10px;
    color: white;
    width: 280px;
}


.publinota-description {
    font-size: 20px;
}

.publinota-link {
    display: flex;
    flex: 1;
    justify-content: center;

}

.publinota-logo-section {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 75%;
    transform: skew(20deg);
}

.publinota-image-section {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding-right: 5%;
} */




/*  v2 */
.publinota-container-v2 {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex: 1;
    height: 220px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
}

.publinota-title-section-v2 {
    display: flex;
    flex: 1.5;
    flex-direction: column;
    color: white;
    margin-left: 2%;
    font-size: 1rem;
}

.publinota-description-v2 {
    font-size: 1rem;
    width: 80%;
}

.publinota-image-section-v2 {
    align-items: center;
    display: flex;
    flex: 1.5;
    justify-content: center;
    height: 100%;
}

.img-v2 {
    max-height: 100%;
    height: auto;
    width: auto;
}

.paralelogramo-v2 {
    display: flex;
    flex: 1;
    height: 100%;
    background: #DCDCDC;
    align-items: center;
    justify-content: center;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    transform: skew(-20deg);
}

.publinota-logo-section-v2 {
    height: auto;
    width: auto;
    transform: skew(20deg);
}

@media (max-width: 600px) {

    /*  v2 */
    .publinota-container-v2 {
        flex: 1;
        border-radius: 0px;
    }

    .publinota-title-section-v2 {
        flex: 1;
        /* font-size: 1.4rem; */

    }

    .publinota-description-v2 {
        font-size: .8rem;
    }

    .publinota-image-section-v2 {
        flex: 1;
    }

    .img-v2 {
        height: auto;
        width: 75%;
    }

    .paralelogramo-v2 {
        flex: 1;
    }

    .publinota-logo-section-v2 {
        height: auto;
        width: 75%;
        transform: skew(20deg);
    }

}


/* 
@media (max-width: 900px) {

    .paralelogramo {
        margin-left: -167px;
        width: 140px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);

    }

    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 160px;
        align-items: center;
        border-radius: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }

    .publinota-image-section {
        height: 80px;
        margin-left: 20px;
    }

    .publinota-title-section h3 {
        margin-left: -5px;
        margin-top: -10px;
        color: white;
        width: 120px;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .publinota-title-section span {
        font-size: 11px;
        margin-left: -5px;
        margin-top: -10px;
        display: block;
        width: 90px;
    }
}

@media (min-width: 450px) {

    .paralelogramo {
        margin-left: -130px;
        width: 160px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);

    }

    .publinota-logo-section {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        transform: skew(20deg);
    }

    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 160px;
        align-items: center;
        border-radius: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }



    .publinota-image-section {
        height: 90px;
        margin-left: 35px;
    }

    .publinota-title-section h3 {
        margin-left: 5px;
        margin-top: -20px;
        color: white;
        width: 150px;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .publinota-title-section span {
        font-size: 10px;
        margin-left: 5px;
        display: block;
        width: 120px;
    }
}

@media (min-width: 600px) {

    .paralelogramo {
        margin-left: -130px;
        width: 160px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);

    }

    .publinota-logo-section {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: auto;
        width: auto;
        transform: skew(20deg);
    }


    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 160px;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }


    .publinota-image-section {
        height: 50px;
        margin-left: 35px;
    }

    .publinota-title-section h3 {
        margin-left: 5px;
        margin-top: -20px;
        color: white;
        width: 150px;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .publinota-title-section span {
        font-size: 10px;
        margin-left: 5px;
        display: block;
        width: 120px;
    }
}

@media (min-width: 750px) {
    .publinota-logo-section {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        transform: skew(20deg);
    }

    .paralelogramo {
        margin-left: -100px;
        width: 200px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);
    }

    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 200px;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }



    .publinota-image-section {
        height: 90px;
        margin-left: 35px;
    }

    .publinota-title-section h3 {
        margin-left: 5px;
        margin-top: -20px;
        color: white;
        width: 150px;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .publinota-title-section span {
        font-size: 10px;
        margin-left: 5px;
        display: block;
        width: 120px;
    }
}

@media (min-width: 1000px) {

    .paralelogramo {
        margin-left: 0px;
        width: 250px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);
    }

    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 230px;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }



    .publinota-image-section {
        height: 200px;
        margin-left: 35px;
    }

    .publinota-title-section h3 {
        margin-left: 5px;
        margin-top: -20px;
        color: white;
        width: 300px;
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .publinota-title-section span {
        font-size: 16px;
        margin-left: 5px;
        display: block;
        width: 200px;
    }

    .publinota-logo-section {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        transform: skew(20deg);
    }
}

@media (min-width: 1300px) {
    .publinota-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 220px;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: linear-gradient(to right, #DB0E15, #DB0E15, #DB0E15 50%, #fff 50%);
    }

    .paralelogramo {
        margin-left: 50px;
        width: 380px;
        height: 100%;
        background: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        transform: skew(-20deg);

    }

    .publinota-logo-section {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        transform: skew(20deg);
    }

    .publinota-title-section {
        margin-left: 10px;
        color: white;
        width: 280px;
        font-size: 14px;
    }

    .publinota-description {
        font-size: 20px;
    }



    .publinota-image-section {

        margin-left: 120px;
        width: 150px;
        height: 150px;
    }

} */