@import "../../styles/globalStyles.css";

.container-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1;

}

.box-modal,
.box-modal-concurso {
  width: 80%;
  max-width: 400px;
  height: auto;
  border: solid 2px rgb(190, 190, 190);
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.box-modal-concurso {
  max-height: 600px;
}

.box-close-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
}

.box-info-modal,
.box-info-modal-concurso {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.box-info-modal-concurso {
  justify-content: space-around;
  gap: 10px;
  text-align: center;
}

.h2-info-modal {
  text-align: center;
  font-size: 20px;
  color: var(--color-rojo);
}

.p-info-modal {
  font-size: 14px;
  text-align: center;
  width: 80%;
}

.box-titulos-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.img-modal-concurso {
  width: 100%;
  max-width: 95%;
}

.parrafo-infoadicional {
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* break long words */
  padding: 0 10px;
}

/* media queries for smaller screens */
@media screen and (max-width: 600px) {

  .box-modal,
  .box-modal-concurso {
    width: 95%;
  }

  .p-info-modal,
  .h2-info-modal {
    font-size: 16px;
  }
}