@import "../../styles/globalStyles.css";

.container-registro,
.header-registro,
.main-registro,
.seccion-registro,
.btns1-seccion-registro,
.inputs-seccion-registro,
.link-politicas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-registro {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.header-registro {
  margin-bottom: 12px;
  gap: 10px;
}

.titulo-registro {
  color: var(--color-rojo);
  font-family: var(--font-family-bold);
}

.main-registro {
  max-width: 510px;
}

.span-header-registro {
  color: var(--color-gris2);
  font-family: var(--font-family-bold);
}

.logo-registro {
  width: 160px;
}

.seccion-registro {
  margin-top: 35px;
}

.titulo-seccion-registro {
  margin-top: 15px;
  color: var(--color-gris);
}

.btns1-seccion-registro {
  margin-top: 10px;
  gap: 15px;
}

.btns1-seccion-registro-mobile {
  margin-bottom: 20px;
}

.inputs-seccion-registro {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.link-politicas-container {
  flex-direction: row;
  width: 100%;
  gap: 5px;
  margin-bottom: 20px;
}

.link-politicas {
  font-size: 11.5px;
  font-weight: bold;
  color: var(--color-gris2);
}

.checkbox-politicas {
  cursor: pointer;
}

.volver-registro {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 20px 0 30px 0;
  cursor: pointer;
  gap: 5px;
  text-decoration: none;
  color: black;
}

.volver-registro-mobile {
  margin-bottom: 0;
  padding-left: 25px;
  justify-content: flex-start;
}

.volver-registro-mobile2 {
  padding-left: 0;
}

.span-error-registro {
  color: var(--color-rojo);
  font-weight: bold;
  border: solid 1px var(--color-rojo);
  padding: 5px;
  z-index: 0;
  width: max-content;
}

.span-error-registro-politicas {
  margin-bottom: 20px;
}

.span-error-registro-psw {
  width: 100%;
  font-size: 12px;
}

.span-ok-registro-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.span-ok-registro {
  color: green;
  font-weight: bold;
  border: solid 1px green;
  padding: 5px;
}

.link-leer-politicas {
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.container-politicas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(228, 228, 228, 0.747);
  z-index: 9999;
}

.container-politicas-div {
  background-color: white;
  border: solid 1px gray;
  border-radius: 5px;
  box-shadow: 10px 10px 10px gray;
  width: 600px;
  height: 250px;
  overflow-y: scroll;
}

.container-politicas-div,
.container-politicas-icon {
  width: 90%;
  max-width: 600px;
}

.container-politicas-div > p {
  padding: 15px 30px 30px 30px;
}

.container-politicas-icon {
  width: 600px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container-politicas-icon-mobile {
  width: 90%;
}

.container-politicas-div-mobile {
  width: 90%;
  height: 350px;
}

.span-politicas-titulo {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.box-registro-conerror {
  display: flex;
  flex-direction: column;
}

.box-titulo-seccion-registro {
  display: flex;
  align-items: center;
}

/* Mui Datepicker */
.date-picker > div {
  width: 240px;
  height: 45px;
}

@media (max-width: 600px) {
  /* Mui Datepicker */
  .date-picker > div {
    width: 250px;
    height: 45px;
  }
  .span-error-registro-psw {
    width: 250px;
  }
  .main-registro {
    max-width: 100%;
    padding: 0 10px; /* you can adjust as necessary */
  }

  .logo-registro {
    width: 120px; /* you can adjust as necessary */
  }

  .inputs-seccion-registro {
    flex-direction: column;
  }

  .link-politicas {
    font-size: 10px; /* you can adjust as necessary */
  }

  .container-politicas-div,
  .container-politicas-icon,
  .container-politicas-icon-mobile {
    width: 100%;
  }

  .container-politicas-div-mobile {
    height: 250px; /* you can adjust as necessary */
  }
}
