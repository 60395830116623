@import "../../styles/globalStyles.css";

.container-nav-oyente,
.box1-nav-oyente,
.box2-nav-oyente,
.opcion-nav-oyente {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gainsboro;
  padding: 1rem;
}

.container-nav-oyente {
  justify-content: space-between;
}

section .box1-nav-oyente {
  align-items: flex-start;
  justify-content: flex-start;
}

.box2-nav-oyente {
  justify-content: flex-end;
  gap: 18px;
}

.hola-nav-oyente {
  position: relative;
  text-align: center;
}

.edit-foto-nav {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  gap: 4px;
  font-size: 12px;
  cursor: pointer;
}

.img-nav-oyente {
  width: 70px;
  height: 70px;
  margin-left: 1rem;
  border-radius: 50%;
}

.opcion-nav-oyente {
  font-size: 13px;
  width: max-content;
  gap: 3px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.opcion-nav-oyente-selected {
  color: var(--color-rojo);
}

.edit-foto-nav-p {
  position: relative;
  cursor: pointer;
}

.input-file-editar-perfil-oyente {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 600px) {
  section .box1-nav-oyente {
    align-items: center;
    justify-content: center;
  }
}
