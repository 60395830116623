.titulo-vista-usuarios-admin {
  text-align: center;
  margin-bottom: 80px;
}
.download-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.download-button svg {
  margin-right: 8px;
}
.download-button-container {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 20px;
}
