@import "../../styles/globalStyles.css";

.container-beneficio-concurso {
  border: dotted 1px rgb(190, 190, 190);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
}

.img-principal-beneficio-concurso {
  width: 150px;
  height: 150px;
}

.box-beneficio-concurso {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.titulo-beneficio-concurso {
  font-size: 18px;
}

.span-beneficio-concurso {
  font-size: 12px;
}

.parrafo-beneficio-concurso,
.textarea-beneficio-concurso {
  font-size: 14px;
}

.textarea-beneficio-concurso {
  width: 450px;
  height: 120px;
  resize: none;
  border: none;
  background-color: transparent;
}

.inbox-beneficio-concurso {
  margin: 7px 0;
}

.inbox2-beneficio-concurso {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.img-banner-beneficio-concurso {
  width: 350px;
  height: 150px;
}

.boxsuperior-beneficio-concurso,
.boxsuperior2-beneficio-concurso {
  width: 50%;
}

.boxsuperior-beneficio-concurso {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.boxsuperior2-beneficio-concurso {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.boton-eliminar-beneficio-concurso {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 3px 5px 3px 0;
  background-color: var(--color-rojo);
  border: none;
  color: white;
  transition: all 400ms;
}

.boton-editar-concurso {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 30%;
  cursor: pointer;
  padding: 3px 5px 3px 0;
  background-color: var(--color-verde);
  border: none;
  color: white;
  transition: all 400ms;
}

.boton-eliminar-beneficio-concurso:hover {
  background-color: #910a0f;
}

.boton-editar-concurso:hover {
  background-color: #03ae2b;
}

.container-beneficio-concurso-hidden {
  display: none;
}

.selector-ganador-concurso {
  border: dotted 1px rgb(190, 190, 190);
  border-top: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 15px;
  margin-bottom: 50px;
}

.input-selector-ganador-concurso {
  text-align: center;
  height: 30px;
  cursor: pointer;
}

.button-selector-ganador-concurso {
  border: solid 1px green;
  background-color: green;
  width: 150px;
  height: 30px;
  cursor: pointer;
  color: white;
  transition: all 400ms;
}

.button-selector-ganador-concurso:hover {
  border: solid 1px rgb(1, 85, 1);
  background-color: rgb(1, 85, 1);
}

.button-selector-ganador-concurso-disabled {
  cursor: default;
  border: solid 1px rgb(97, 97, 97);
  background-color: rgb(97, 97, 97);
  pointer-events: none;
}

.button-selector-ganador-concurso-disabled:hover {
  border: solid 1px rgb(97, 97, 97);
  background-color: rgb(97, 97, 97);
}

.ganador-concurso-text {
  font-size: 20px;
}

.ganador-concurso-text > span {
  color: green;
}

.botones-concurso {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .container-concurso-completo {
    border: dotted 1px rgb(153, 153, 153);
  }

  .container-beneficio-concurso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .container-beneficio-concurso-hidden {
    display: none;
  }

  .boxsuperior-beneficio-concurso,
  .boxsuperior2-beneficio-concurso {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; /* Align items center */
    margin-bottom: 20px;
  }

  .box-beneficio-concurso {
    display: flex;
    justify-content: space-between; /* Add space between */
    align-items: center; /* Align items center */
  }

  .box-beneficio-concurso img {
    margin-right: 20px; /* Optional, add space between image and icons */
  }

  .inbox-beneficio-concurso,
  .inbox2-beneficio-concurso {
    display: flex;
    flex-direction: column;
  }

  .img-principal-beneficio-concurso,
  .img-banner-beneficio-concurso {
    width: 100%;
    height: auto;
  }
  .img-principal-beneficio-concurso {
    max-width: 200px;
  }

  .titulo-beneficio-concurso {
    font-size: 24px;
    color: #000;
    margin-bottom: 10px;
  }

  .span-beneficio-concurso {
    font-size: 16px;
    color: #000;
  }

  .parrafo-beneficio-concurso {
    font-size: 14px;
    color: #666;
  }

  .textarea-beneficio-concurso {
    width: 100%;
    height: 100px;
    resize: none;
  }

  .boton-eliminar-beneficio-concurso,
  .boton-editar-concurso {
    position: relative;
    background: #f00;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    right: auto;
  }

  .boton-editar-concurso {
    background: var(--color-verde);
  }

  .selector-ganador-concurso {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .span-ok-registro {
    color: #008000;
    font-size: 16px;
  }

  .span-error-registro {
    color: #f00;
    font-size: 16px;
  }

  .ganador-concurso-text {
    font-size: 20px;
    color: #000;
  }

  .ganador-concurso-text span {
    font-weight: bold;
  }
  .botones-concurso {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
