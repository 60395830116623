@import "../../styles/globalStyles.css";

.go-back-text-nav-admin {
  font-family: var(--font-family-bold);
  color: var(--color-blanco);
  width: 100%;
  font-size: px;
}

.go-back-nav-bar-admin {
  top: 10px;
  padding: 5px;
  text-align: center;
  width: 100vw;
  cursor: pointer;
  font-family: var(--font-family-bold);
  background-color: var(--color-rojo);
  color: var(--color-blanco);
  display: flex;
  align-items: center;
}
.img-nav-oyente-mobile {
  margin-top: 0px;
}
.header-admin-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.header-admin-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 5px;
}

.box1-nav-oyente {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-foto-nav-select {
  color: var(--color-rojo);
}
