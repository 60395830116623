.container-admin{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.box-section-vistas-admin{
    margin-top: 120px;
    width: 60%;
}
.box-section-vistas-admin-mobile{
    margin-top: 50px;
    width: 100%;
}

.hidden-element-nav-admin{
    display:none;
}

.loader-admin{
    margin: 0;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .box-section-vistas-admin-mobile{
        margin-top: 160px;
        width: 100%;
    }
    .container-admin{
        justify-content: center;
        align-items: center;
    }
}