@import '../../styles/globalStyles.css';

.container-sumate, 
.header-sumate, 
.main-sumate, 
.header-seccion,
.seccion-sumate,
.btns1-seccion,
.btn2-seccion{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-sumate{
    width: 100%;
    height: 100vh;
}

.header-sumate{
    margin-bottom: 5%;
    gap: 10px;
}

.titulo-sumate{
    color: var(--color-rojo);
    font-family: var(--font-family-bold);
}

.main-sumate{
    max-width: 510px;
}

.parrafo-sumate{
    width: 80%;
    text-align: center;
    color: var(--color-gris);
    font-size: 16px;
    line-height: 25px;
    margin: 30px 0 20px 0;
}

.span-header{
    color: var(--color-gris2);
    font-family: var(--font-family-bold);
}

.logo-sumate{
    width: 160px;
}

.seccion-sumate{
    padding: 20px;
}

.header-seccion{
    gap: 5px;
    margin-bottom: 20px;
}

.titulo-seccion{
    color: var(--color-gris);
}

.subtitulo-seccion{
    font-weight: 900;
    color: var(--color-rojo);
    font-family: var(--font-family-bold);
}

.btns1-seccion{
    gap: 15px;
}

.btn2-seccion{
    margin-top: 22px;
    gap: 7px;
}

.text-seccion{
    font-size: 12px;
}

.espacio-boton-inicio-sesion{
    margin-top: 50px;
}

.span-error-inicio-sesion-google{
    color: var(--color-rojo);
    font-weight: bold;
    border: solid 1px var(--color-rojo);
    padding: 5px;
    margin-top: 15px;
}

.width-google-error{
    text-align: center;
    width: 300px;
}

@media (max-width: 400px) {
    .header-sumate {
        margin-top: 100px;
        margin-bottom: 0px;
    }
    .espacio-boton-inicio-sesion{
        margin-top: 10px;
    }
}


@media (max-width: 400px) {
    .main-sumate {
        padding: 20px;
        max-width: 85%;
    }
}

/* rest of the CSS ... */

@media (max-width: 400px) {
    .width-google-error {
        width: 85%;
    }
}