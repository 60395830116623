@import "../../../styles/globalStyles.css";

.container-graficos-estadisticas {
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.titulo-vista-estadisticas-admin {
  text-align: center;
  margin-bottom: 80px;
}

.titulo-grafico-estadisticas {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: var(--font-family-bold);
  word-wrap: break-word;
}

.box-grafico-estadisticas {
  margin-top: 50px;
  width: 350px;
  overflow-x: auto;
}

.datos-insuficientes-estadisticas {
  color: red;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 600px) {
  .box-grafico-estadisticas {
    margin-top: 50px;
    width: 100%;
  }
}
