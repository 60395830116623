@import '../../../../styles/globalStyles.css';


.container-nav-concursos-admin{
    width: 100%;
    display: flex;
    justify-content: space-around;  
}

.opciones-nav-admin-concursos{
    flex: 1 0 auto;
    border: solid 1px rgb(190, 190, 190);
    padding: 8px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow-wrap: break-word;  /* wrap long words to the next line */
}

.opciones-nav-admin-concursos:hover {
    background-color: #f5f5f5; 
}

.opciones-nav-admin-concursos-select{
    flex: 1 0 auto;
    font-family: var(--font-family-bold);
    border: solid 2px var(--color-rojo);
    color: var(--color-rojo);
    overflow-wrap: break-word;  /* wrap long words to the next line */
}

@media (max-width: 600px) {

    .container-nav-concursos-admin{
        flex-direction: column;
    }
}