@import "../../styles/globalStyles.css";

.header-oyente-mobile,
.header-oyente-mobile-2,
.box-botonera-mobile,
.nav-mobile-oyente {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.header-oyente-mobile {
  justify-content: flex-start;
}

.header-oyente-mobile-2 {
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  margin: 0px;
  padding: 0px 20px 20px 20px;
}

.seccion-oyente-mobile {
  width: 100%;
}

.box-botonera-mobile {
  gap: 20px;
  margin-bottom: 0px;
}

.box-botonera-redirecciones {
  flex-direction: row;
  justify-content: space-evenly;
}
.box-botonera-redirecciones > div {
  color: #9b0000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-botonera-mobile-footer {
  border-top: solid black 1px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.box-botonera-mobile-perfil {
  margin-top: 0;
}

.nav-mobile-oyente {
  gap: 35px;
}

.button-nav-oyente-mobile {
  width: 90%;
  background-color: var(--color-rojo);
  padding: 10px 5px;
  color: white;
  border: none;
  cursor: pointer;
}
.button-redirecciones-oyente {
  width: 60%;
  border-radius: 2rem;
  background-color: #9b0000;
}

.button-nav-oyente-mobile > p {
  font-size: 16px;
}

.logo-mobile {
  width: 140px;
}

.logo-mobile-2 {
  width: 90px;
}

.img-nav-oyente-mobile {
  width: 55px;
  height: 55px;
  margin-top: 50px;
}

.box-header-mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.img-nav-oyente-mobile-2 {
  cursor: pointer;
  width: 45px;
  height: 45px;
}

.titulo-header-mobile {
  font-size: 20px;
}

.opcion-nav-oyente-mobile {
  font-size: 12px;
}

.inbox-header-mobile {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.atras-nav-mobile {
  background-color: var(--color-rojo);
  width: 100%;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0 10px;
}

.atras-nav-mobile > p {
  width: 100%;
  font-size: 18px;
  font-family: var(--font-family-bold);
  text-align: center;
}

.editar-foto-perfil-mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
  padding-right: 30px;
}

.inbox-editar-foto-perfil-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 5px;
}

.inbox-editar-foto-perfil-mobile > input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.mobile-boton-red {
  background-color: var(--color-rojo);
  border: solid 1px var(--color-rojo);
  color: var(--color-blanco);
  width: 100%;
  height: 30px;
  font-size: 12px;
}
