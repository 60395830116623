@import '../../styles/globalStyles.css';

.container-tarjeta-oyente{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: solid 1px black;
    padding: 20px 0;
}

.img-tarjeta-oyente, .box-img-mobile-exact{
    width: 120px;
    height: 120px;
    margin-right: 10px;
}

.box-tarjeta-oyente, .box-texto-tarjeta-oyente{
    width: 100%;
    height: 150;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.box-texto-tarjeta-oyente{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ver-mas-tarjeta{
    color: var(--color-rojo);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.titulo-tarjeta-oyente{
    font-size: 20px;
}

.texto-tarjeta-oyente{
    font-size: 12px;
    letter-spacing: 0.5px;
}

.info-tarjeta-oyente{
    font-size: 16px;
    font-family: var(--font-family-bold);
    text-align: end;
}

.retiro-premio-tarjeta{
    font-size: 12px;
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
}

.info-descuento-tarjeta-oyente{
    text-align: end;
    cursor: pointer;
}

.ver-mas-tarjeta-oculta{
    opacity: 0;
    cursor: default;
    pointer-events: none;
}

.bold-nombre-ganador{
    font-weight: bold;
    color: red;
}

.box-ganador-boton, .box-ganador-boton-pc{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.box-ganador-boton > p{
    font-size: 11px;
}

@media screen and (max-width: 600px){
    .mobile-botones-tarjetas{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .container-tarjeta-oyente{
        padding: 25px 20px;
        border-top: none;
        border-bottom: solid 1px black;
    }

    .titulo-tarjeta-oyente{
        font-size: 16px;
    }

    .box-texto-tarjeta-oyente{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .info-tarjeta-oyente, .retiro-premio-tarjeta{
        text-align: start;
    }

    .codigo-descuento-mobile{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info-tarjeta-oyente-mobile, .info-descuento-tarjeta-oyente{
        font-size: 12px;
    }

    .box-tarjeta-oyente-mobile{
        gap: 8px;
    }

}