/* Make headers bold and a bit larger */
.TableHeader {
  font-weight: bold;
  font-size: 1.2em;
}

/* Highlight active column */
.ActiveColumn {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Add cursor to show headers are clickable */
.TableHeader,
.TableSortLabel-root {
  cursor: pointer;
}

/* Style the arrow icon */
.TableSortLabel-icon {
  color: #3f51b5; /* You can change this color as needed */
}
